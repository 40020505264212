import React from 'react'
import whitelogo from '../mokx-white-logo.svg';
import { NavLink, Link } from "react-router-dom";
import Header from '../components/Header'
import Footer from '../components/Footer'
// import { HashLink } from 'react-router-hash-link';

function AccountBookkepping() {
  return (
    <>
        <Header></Header>
        <section className='tax-services'>
            <div className='container'>
              <div className='sec-title'>
                <div className='heding-sec'>
                  <div className='sec-heading'>
                      <p className='small-heading'>
                      Home - Services - Accounting and Bookkeeping
                      </p>
                      <p className='main-heading'>Accounting and Bookkeeping Services
                      </p>
                  </div>
                </div>
              </div>
            </div>
        </section>

        <section className='tax-sercices-sec'>
            <div className='direct-tax-service'>
                <div className='container'>
                    {/* <div className='sec-title'>
                        <div className='heding-sec'>
                            <div className='sec-heading'>
                                <p className='main-heading'>Accounting and Bookkeeping
                                </p>
                            </div>
                        </div>
                    </div> */}
                    <div className='service-content'>
                        <h2 className='semi-bold'>Accurate financial reporting is essential to the success of any business. Contact us to learn more about our accounting services.</h2>
                        <h2 className='semi-bold'>
                        Tailored, synergistic, and reliable cloud-based accounting and bookkeeping services
                        </h2>
                        <p>Precise and efficient accounting and bookkeeping constitute the cornerstone of any thriving enterprise. Establishing and upholding consistent financial tracking and reporting fortifies the business's overall health. Conversely, inconsistent or inaccurate financial reporting hampers your capacity to expand and adapt to evolving market conditions.</p>
                        <p>Our professionals recognize this challenge. We also comprehend that delivering tailored support for your business necessitates possessing profound industry knowledge, access to advanced digital platforms and tools, a steadfast dedication to offering human-centered solutions, and the capacity to scale our services in alignment with your evolving requirements.</p>
                            
                        <div className='tax-offers'>
                            <h2>Bookkeeping</h2>
                            <ul>
                                <li>
                                Accounts Payable
                                    
                                </li>
                                <li>Accounts Receivable
                        
                                </li>
                                <li>
                                Reconciliation of business transactions (bank account & credit cards)
                                    
                                </li>
                                <li>
                                Posting of sales and reconciliation of payments
                                </li>
                                <li>
                                Payroll
                                    
                                </li>
                                <li>
                                Monthly financial analysis reporting (income statement and balance sheet)
                                   
                                </li>
                                <li>
                                Maintenance of general ledger
                                </li>
                            </ul>
                        </div>
                        <div className='tax-offers'>
                            <h2>Accounting & Tax</h2>
                            <ul>
                                <li>
                                Corporate Tax Return (T2)
                                    
                                </li>
                                <li>Government filings (GST, HST, WSIB, EHT)
                        
                                </li>
                                <li>
                                Personal Tax (T1)
                                    
                                </li>
                                <li>
                                Tax Planning & Minimization for Business Owner(s)
                                </li>
                                <li>
                                Audit Support
                                    
                                </li>
                                <li>
                                CRA representation
                                   
                                </li>
                            </ul>
                        </div>
                        <div className='tax-offers'>
                            <h2>Virtual CFO & Controller</h2>
                            <ul>
                                <li>
                                Guidance from a trusted CPA advisor  
                                </li>
                                <li>Budgeting and cash flow forecasting
                                </li>
                                <li>
                                Gross margin analysis by product line, liquidity ratios
                                </li>
                                <li>
                                Goal setting
                                </li>
                                <li>
                                Transaction advisory (liquidity events, contracts, etc.)
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> 
            </div>
            
            <div className='faqs-sec'>
                <div className='container'>
                    <div className='sec-title'>
                        <div className='heding-sec'>
                            <div className='sec-heading'>
                                <p className='main-heading'>Frequently asked questions (faq)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='faqs-content'>
                        <div className='row'>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div className=''>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                            What is a Corporate Tax Return (T2), and do you prepare it?
                                            </button>
                                            </h2>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample" >
                                            <div class="accordion-body">A Corporate Tax Return (T2) is a tax filing required for Canadian corporations to report their income, expenses, and taxes owed. We handle the preparation and filing of your T2 return, ensuring compliance with CRA requirements and helping to minimize your </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsetwo" aria-expanded="true" aria-controls="flush-collapsetwo">
                                            What government filings do you handle?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsetwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">We manage various government filings, including GST/HST returns, Workers' Safety and Insurance Board (WSIB) filings, and Employer Health Tax (EHT) remittances. Our services ensure that your business remains compliant with federal and provincial tax obligations.</div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="true" aria-controls="flush-collapsethree">
                                            What guidance can I expect from a CPA advisor?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsethree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">A Our CPA advisors provide expert guidance on tax planning, business strategy, financial analysis, and compliance. We work closely with you to understand your business goals and offer tailored solutions to improve your financial performance and growth prospects.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="true" aria-controls="flush-collapsefour">
                                            How can you help with goal setting for my business?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsefour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">We assist in setting both short-term and long-term financial goals, helping you align your business strategies with measurable targets. This includes financial planning, performance tracking, and adjusting strategies to ensure progress toward your objectives.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="true" aria-controls="flush-collapsefive">
                                            Do you handle personal tax (T1) filing for business owners?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsefive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">Yes, we prepare and file personal tax returns (T1) for business owners, ensuring that personal income, business income, and other tax considerations are handled effectively for both compliance and tax minimization.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="true" aria-controls="flush-collapsesix">
                                            How do you help with tax planning and minimization for business owners?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsesix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">The Our tax planning services are designed to reduce your overall tax liability through strategies like income splitting, expense optimization, and maximizing tax credits. We develop a tax-efficient structure that aligns with your business and personal financial goals.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="true" aria-controls="flush-collapseseven">
                                            What type of audit support do you provide?
                                            </button>
                                            </h2>
                                            <div id="flush-collapseseven" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">We offer comprehensive audit support, ensuring that your financial records are well-organized and compliant with regulatory requirements. Our team can assist you through the audit process, including preparing documentation and representing you before the CRA.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseight" aria-expanded="true" aria-controls="flush-collapseight">
                                            Can you represent my business with the CRA?
                                            </button>
                                            </h2>
                                            <div id="flush-collapseight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">Yes, we provide full CRA representation, assisting with audits, tax disputes, and other communications with the Canada Revenue Agency. Our goal is to protect your business and ensure compliance with tax laws while minimizing any potential penalties.
                                            </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
        
        <Footer></Footer>
    </>
  )
}

export default AccountBookkepping;