import React from 'react'
import whitelogo from '../mokx-white-logo.svg';
import { NavLink, Link } from "react-router-dom";
import Header from '../components/Header'
import Footer from '../components/Footer'
// import { HashLink } from 'react-router-hash-link';

function Tax() {
  return (
    <>
        <Header></Header>
        <section className='tax-services'>
            <div className='container'>
              <div className='sec-title'>
                <div className='heding-sec'>
                  <div className='sec-heading'>
                      <p className='small-heading'>
                      Home - Services - Tax Services
                      </p>
                      <p className='main-heading'>Tax services
                      </p>
                  </div>
                </div>
              </div>
            </div>
        </section>

        <section className='tax-sercices-sec'>
            <div className='direct-tax-service'>
                <div className='container'>
                    <div className='sec-title'>
                        <div className='heding-sec'>
                            <div className='sec-heading'>
                                <p className='main-heading'>Direct tax service
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='service-content'>
                        <h2 className='semi-bold'>Our team of tax specialists consistently receives excellent reviews.</h2>
                        <p>At Mokx Professionals, we are dedicated to provide you with comprehensive guidance on the implications of evolving tax regulations and rulings on your business. Our expertise enables us to craft a meticulously tailored tax strategy designed to navigate and mitigate corporate tax challenges effectively. You can rely on our tax specialists to evaluate the efficacy of your business structure, uncover potential tax-saving opportunities, engage in strategic income tax planning, and develop a bespoke tax program tailored to your organization's needs.</p>
                        <p>Owning a business entails more than just addressing corporate tax concerns; it also necessitates an understanding of how tax regulations influence your personal and familial tax obligations. Given the intricacies and continual modifications of personal tax laws, our experts are well-positioned to assist you in managing your overall tax situation. We will work closely with you to assess how applicable tax rules may impact your personal and family finances, ensuring a holistic approach to your tax management.</p>
                            
                        <div className='tax-offers'>
                            <h2>Tax Services We Offer</h2>
                            <p>We provide advice and services for the following:</p>
                            <ul>
                                <li>
                                    Corporate tax and tax planning for businesses.
                                    <ol type="I">
                                        <li>What type of corporate structure should I use?</li>
                                        <li>What type of share classes should I have in the Article of Incorporation?</li>
                                        <li>How do I introduce family members as shareholders?</li>
                                    </ol>
                                </li>
                                <li>Personal tax planning for business owners and high net worth individuals.</li>
                                <li>
                                Optimizing the Capital Gains Exemptions (including executing the capital gains strip transaction).
                                </li>
                                <li>
                                Tax diagnostic reviews.
                                </li>
                                <li>
                                Identification of tax saving opportunities and issues.
                                </li>
                                <li>
                                Tax Consulting.
                                </li>
                                <li>
                                CRA Audit Support: Provide guidance and representation during CRA audits and reviews related to direct tax.
                                </li>
                                <li>
                                Tax services for non- residents of Canada selling Canadian real estate. If you are selling Canadian property, but you are not a Canadian, we can help with your filing requirements which may include: Forms T2062, T2068, Section 116 Certificate
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> 
            </div>
            <div className='direct-tax-service'>
                <div className='container'>
                    <div className='sec-title'>
                        <div className='heding-sec'>
                            <div className='sec-heading'>
                                <p className='main-heading'>Indirect tax service
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='service-content'>
                        <h2 className='semi-bold'>Assisting you in navigating and managing your obligations pertaining to indirect taxation</h2>
                        <p>In one capacity or another, the majority of transactions are subject to indirect taxation. Given that such transactions encompass sales, acquisitions, imports and exports, as well as payroll activities, the implications of indirect taxes extend beyond mere cash flow considerations; they can also constitute a substantial financial burden for your enterprise.</p>
                        <h2>How we can help</h2>
                        <p>Leveraging extensive industry expertise and profound technical acumen, coupled with an unwavering commitment to vigilant monitoring of legislative, judicial, and administrative policy developments, our Indirect Tax specialists are well-positioned to assist you in effectively managing your indirect tax obligations and mitigating the associated financial burdens on your business.</p>
                        <p>Allow us the opportunity to support you. Please reach out to a Mokx today.</p>
                            
                        <div className='tax-offers'>
                            <h2>Tax Services We Offer</h2>
                            <p>We provide advice and services for the following:</p>
                            <ul>
                                <li>
                                GST/HST registration and return filling service.
                                    
                                </li>
                                <li>PST registration and return filling service.</li>
                                <li>
                                RST return filling service.
                                </li>
                                <li>
                                GST/HST new housing rebate.
                                </li>
                                <li>
                                HST self assessment on substantial completion of rental apartment building.
                                </li>
                                <li>
                                Indirect Tax advisory service.
                                </li>
                                <li>
                                GST/HST Audit Support: Provide guidance and representation during CRA audits and reviews related to GST/HST.
                                </li>
                                <li>
                                PST Audit Support.
                                </li>
                                <li>Import Export Registration Service.</li>
                            </ul>
                        </div>
                    </div>
                </div> 
            </div>
            <div className='faqs-sec'>
                <div className='container'>
                    <div className='sec-title'>
                        <div className='heding-sec'>
                            <div className='sec-heading'>
                                <p className='main-heading'>Frequently asked questions (faq)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='faqs-content'>
                        <div className='row'>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div className=''>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                            Why should I file my taxes?
                                            </button>
                                            </h2>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample" >
                                            <div class="accordion-body">Filing your taxes ensures that you comply with Canadian tax laws and avoid penalties. It also allows you to access potential refunds, tax credits, and government benefits such as the Canada Child Benefit (CCB) and the Goods and Services Tax/Harmonized Sales Tax (GST/HST) credit.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsetwo" aria-expanded="true" aria-controls="flush-collapsetwo">
                                            When are taxes due in Canada?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsetwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">For individuals, the tax filing deadline is April 30 of the following year. If you're self-employed, the deadline is June 15, though any taxes owing are still due by April 30. For corporations, the filing deadline is generally six months after the end of the fiscal year, with taxes owing typically due within two or three months, depending on the type of corporation.</div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="true" aria-controls="flush-collapsethree">
                                            How long does it take to get a tax refund in Canada?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsethree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">The time it takes to receive a tax refund depends on how you file your return. If you file electronically with direct deposit, it typically takes 2 to 8 weeks. Paper filings can take up to 12 weeks.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="true" aria-controls="flush-collapsefour">
                                            Does everyone need to file an income tax return?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsefour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">Not everyone is required to file a return, but it's beneficial to do so. Individuals with income above the basic personal amount or those owing taxes must file. Even if you don't owe taxes, filing can allow you to receive benefits like GST/HST credits or the Canada Workers Benefit.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="true" aria-controls="flush-collapsefive">
                                            How do I determine my employment income?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsefive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">Employment income includes wages, salaries, bonuses, commissions, and taxable benefits received from your employer. It is typically reported on a T4 slip provided by your employer, which you must include in your tax return.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="true" aria-controls="flush-collapsesix">
                                            What are RRSPs, their limits, and withdrawal options?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsesix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">A Registered Retirement Savings Plan (RRSP) allows you to save for retirement while reducing your taxable income. Contributions are tax-deductible, and investment growth is tax-deferred. The contribution limit is 18% of your earned income from the previous year, up to a maximum set by the government (for 2024, the limit is $31,560). Withdrawals are generally taxed but can be made tax-free under certain programs like the Home Buyers’ Plan (HBP) and Lifelong Learning Plan (LLP).</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="true" aria-controls="flush-collapseseven">
                                            What are the benefits of RRSPs?
                                            </button>
                                            </h2>
                                            <div id="flush-collapseseven" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">RRSPs offer the dual benefit of tax savings and retirement security. Contributions reduce your taxable income, and the investment grows tax-free until withdrawal, allowing for potential compound growth. RRSPs can also be used to fund a first home or education without immediate tax consequences under specific programs.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseight" aria-expanded="true" aria-controls="flush-collapseight">
                                            What is  First Home Savings Account, its key features, limits and criteria
                                            </button>
                                            </h2>
                                            <div id="flush-collapseight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">
                                            <p>The <span className='semi-bold'>First Home Savings Account (FHSA) </span> is a Canadian savings plan introduced to help first-time homebuyers save for a down payment. This registered plan combines tax advantages of both a Registered Retirement Savings Plan (RRSP) and a Tax-Free Savings Account (TFSA) to encourage homeownership. Here’s a breakdown:</p>
                                            
                                            <p className='semi-bold'>Key Features of the FHSA</p>
                                            <ol>
                                                
                                                <li>
                                                    <span className='semi-bold'>
                                                    Tax Deductibility: 
                                                    </span>Contributions to an FHSA are tax-deductible, similar to RRSPs, meaning that contributions can reduce your taxable income for the year.
                                                </li>
                                                <li>
                                                    <span className='semi-bold'>
                                                    Tax-Free Growth 
                                                    </span>Funds in the FHSA grow tax-free, as with TFSAs.
                                                </li>
                                                <li>
                                                    <span className='semi-bold'>
                                                    Tax-Free Withdrawals:  
                                                        </span>Withdrawals for purchasing a first home are tax-free, provided you meet eligibility requirements.
                                                </li>
                                               
                                            </ol>

                                            <p className='semi-bold'>Contribution and Lifetime Limits</p>
                                            <ul>
                                                
                                                <li>
                                                The <span className='semi-bold'>annual contribution limit</span> is $8,000, with a <span className='semi-bold'>life time limit</span> of $40,000.
                                                </li>
                                                <li>
                                                Unused contribution room can be carried forward to future years, though only up to a maximum of $8,000 can be contributed in any one year, even if carry-forward room is available.
                                                </li>
                                            </ul>

                                            <p className='semi-bold'>Eligibility Criteria for FHSA</p>
                                            <ol>
                                                
                                                <li>
                                                The <span className='semi-bold'>Residency: </span> You must be a resident of Canada.
                                                </li>
                                                <li>
                                                The <span className='semi-bold'>Age:</span> You must be at least 18 years old and below the age of 71.
                                                </li>
                                                <li>
                                                The <span className='semi-bold'>First-Time Homebuyer Status: </span> You must not have owned a home in the year you open the FHSA or any of the four preceding calendar years.
                                                </li>
                                                <li>
                                                The <span className='semi-bold'>Intended Use: </span> The account must be used to buy a qualifying first home, defined as a principal residence in Canada.
                                                </li>
                                            </ol>
                                            </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
        
        <Footer></Footer>
    </>
  )
}

export default Tax;