import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import "bootstrap/dist/js/bootstrap.bundle.js";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "jquery/dist/jquery.js";
import App from './App';
import Aboutus from './pages/Aboutus';
import Contactus from './pages/Contactus';
import Tax from './pages/Tax';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider,} from "react-router-dom";
import Payroll from './pages/Payroll';
import AccountBookkepping from './pages/AccountingBookkeeping';
import SoftwareSupport from './pages/SoftwareSupport';
import BuinessAdvisory from './pages/buiness-advisory';

const router = createBrowserRouter([

  {
    path: "/",
    element: <App />,
  },

  {
    path: "/home",
    element: <App />,
  },

  {
    path: "/aboutus",
    element: <Aboutus />,
  },

  {
    path: "/contactus",
    element: <Contactus />,
  },

  {
    path: "/tax-services",
    element: <Tax />,
  },

  {
    path: "/payroll-services",
    element: <Payroll />,
  },

  {
    path: "/accountingandbookkeeping-services",
    element: <AccountBookkepping />,
  },

  {
    path: "/software-implementation-support-services",
    element: <SoftwareSupport />,
  },

  {
    path: "/buiness-advisory-services",
    element: <BuinessAdvisory />,
  },

  

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
