import React from 'react'
import whitelogo from '../mokx-white-logo.svg';
import { NavLink, Link } from "react-router-dom";
import Header from '../components/Header'
import Footer from '../components/Footer'
// import { HashLink } from 'react-router-hash-link';

function Contactus() {
  return (
    <>
        <Header></Header>
        <section className='contact-us'>
            <div className='container'>
              <div className='sec-title'>
                <div className='heding-sec'>
                  <div className='sec-heading'>
                      <p className='small-heading'>
                      Home - Contact Us
                      </p>
                      <p className='main-heading'>Contact Us
                      </p>
                  </div>
                </div>
              </div>
            </div>
        </section>
        
        <section className='contact-us-form'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 col-xl-6 col-xxl'>
                        <div className='contact-us-right'>
                            <div className='sec-title'>
                                <div className='heding-sec'>
                                    <div className='sec-heading'>
                                        <p className='small-heading'>
                                        SCHEDULE AN INTRO CALL
                                        </p>
                                        <p className='main-heading w-100'>Your integrated finance team is just a call away
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='contact-us-right-details'>
                                <p>We're thrilled to share that Associates was mentioned in a recent Wall Street Journal article about influencers and their taxes! But the recognition doesn’t stop there.</p>
                                <p className='semi-bold'>During your 15-minute call, our financial expert will determine whether we’re a great mutual fit by learning:</p>
                                <div className='call-discuss-keys'>
                                    <div class="call-discuss-keys-points">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path></svg><p>How you run your business and your growth goals</p>
                                    </div>
                                    <div class="call-discuss-keys-points">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path></svg><p>What you’re currently using for your accounting and taxes</p>
                                    </div>
                                    <div class="call-discuss-keys-points">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path></svg><p>How much time you invest to support your back office day-to-day</p>
                                    </div>
                                    <div class="call-discuss-keys-points">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path></svg><p>What’s your budget?</p>
                                    </div>
                                </div> 
                                <p className='semi-bold'>Let’s talk and help you regain the time you need to run your business.</p>
                            </div>
                            <div className='contact-details'>
                                <div className='contact-inner'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
                                    <p className="m-0">
                                        1222 Alberta Ave #131, <br></br>Saskatoon, SK S7K 1R4
                                    </p>
                                </div>
                                <div className='contact-inner'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
                                    <p className="m-0">
                                        <a href='tel:+1 (437) 410-4371'>+1 (437) 410-4371</a>
                                    </p>
                                </div>
                                <div className='contact-inner'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                                    <p className="m-0">
                                        <a href='mailto:contact@mokx.ca'>contact@mokx.ca</a>
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-xl-6 col-xxl'>
                        <div className='contact-form'>
                            <div className='contact-title'>
                                <h2>let’s get started!</h2>
                                <p>Please reach out using the form below and we'll get back to you right away!</p>
                            </div>
                            <form method='POST'>
                                <div className='row'>
                                    <div className='col-12 col-sm-6 col-md-6 col-xl-6 col-xxl-6'>
                                        <label>First name:</label>
                                        <input type="text" name="Name" class="form-control" placeholder='Enter first name' required />
                                    </div>
                                    <div className='col-12 col-sm-6 col-md-6 col-xl-6 col-xxl-6'>
                                        <label>Last name:</label>
                                        <input type="text" name="Contact" class="form-control" placeholder='Enter last name'  required />
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-12 col-xl-6 col-xxl-6'>
                                        <label>Email ID:</label>
                                        <input type="email" name="Email" class="form-control" placeholder='Enter Email ID'  required />
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-12 col-xl-6 col-xxl-6'>
                                        <label>Contact number:</label>
                                        <input type="number" name="number" class="form-control" placeholder='Enter contact number'  required />
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-12 col-xl-12 col-xxl-12'>
                                        <label>Srvices:</label>
                                        <select class="form-select" name="Service"  required>
                                            <option selected>Select Tax Services</option>
                                            <option value="Tax Services" >Tax Services</option>
                                            <option value="Payroll Services" >Payroll Services</option>
                                            <option value="Accounting & Bookkeeping" >Accounting & Bookkeeping</option>
                                            <option value="Software Implementation Support Service" >Software Implementation Support Service</option>
                                            <option value="Business Advisory" >Business Advisory</option>
                                        
                                        </select>
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-12 col-xl-12 col-xxl-12 '>
                                        <label>Requirements:</label>
                                        <textarea class="form-control" rows={'8'} placeholder="Enter detailed requirements..." name="Message" required></textarea>
                                    </div>
                                </div>
                                <button className='' type='submit'>Submit <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451" />
                                </svg>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className='google-map'>
                    <iframe title='goole map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9789.950302527172!2d-106.6675163!3d52.1446233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5304f7167132d7ab%3A0xfb5cc0d521523430!2sMokx%20Professional%20Services%20Ltd.!5e0!3m2!1sen!2sca!4v1730187434218!5m2!1sen!2sca" width="100%" height="550" style={{border: '0'}} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
        </section>
        <Footer></Footer>
    </>
  )
}

export default Contactus;